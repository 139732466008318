import { getTempAuthToken } from "./getTempAuthToken";

export const apiClient = async <T = any>(
  url: string,
  options?: RequestInit
) => {
  const { headers: optionsHeaders, ...restOptions } = options || {};

  const headers = new Headers({
    ...optionsHeaders,
  });

  if (!process.env.REACT_APP_IS_PROD) {
    headers.append("is-sandbox", "true");
  }

  const tempAuthToken = await getTempAuthToken();

  headers.append("api-auth-key", tempAuthToken);

  const response = await fetch(`${process.env.REACT_APP_API_URL || ""}${url}`, {
    headers,
    ...restOptions,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json() as Promise<T>;
};
