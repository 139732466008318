import styles from "./button.module.css";

type Props = {
  onClick: () => void;
  isDisabled?: boolean;
  className?: string;
  children: React.ReactNode;
};

export const Button = ({ onClick, isDisabled, className, children }: Props) => {
  return (
    <button
      className={`${styles.button} ${className || ""}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};
