import { useEffect } from "react";
import { useReadLocalStorage } from "usehooks-ts";
import { useCookies } from "react-cookie";

export const useSetQueryParams = () => {
  const onboardingData =
    useReadLocalStorage<{ [key in string]: string[] }>("onboarding");
  const [cookies] = useCookies(["temp-auth-token"]);

  useEffect(() => {
    const url = new URL(window.location.href);

    if (onboardingData?.["email"]) {
      url.searchParams.set("email", onboardingData["email"][0]);
    }

    if (cookies["temp-auth-token"]) {
      url.searchParams.set("temp_user_auth", cookies["temp-auth-token"]);
    }

    window.history.pushState(null, "", url);
  }, [onboardingData, cookies]);
};
