import style from './styles.module.css';
import { useCountdown } from '../../hooks/useCountDown';
import { CountdownDateDisplay } from './countdown-date-display';

export const CountDown = () => {
  const [hours, minutes, seconds] = useCountdown(60 * 60);

  return (
    <div className={style.countdown}>
      <CountdownDateDisplay label="Hours" value={hours} />
      <span className={style.countdown__divider}>:</span>
      <CountdownDateDisplay label="Minutes" value={minutes} />
      <span className={style.countdown__divider}>:</span>
      <CountdownDateDisplay label="Seconds" value={seconds} />
    </div>
  );
};
