import style from './styles.module.css';

type Props = {
  label: string;
  value: number;
};

export const CountdownDateDisplay = ({ value, label }: Props) => {
  const _value = String(value);

  return (
    <div className={style.countdown__block}>
      <div className={style.countdown__items}>
        <span className={style.countdown__item}>{_value.length < 2 ? 0 : _value[0]}</span>
        <span className={style.countdown__item}>{_value.length < 2 ?  _value[0] : _value[1]}</span>
      </div>
      <span className={style.countdown__block_label}>{label}</span>
    </div>
  );
};
