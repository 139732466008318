const offerData = [
  {
    productId: 'prod_RI1llWknx1YIGn',
    priceId: 'price_1QPRiIA3VB2ACp95SGOWd6bZ',
  },
  {
    productId: 'prod_RI1tRIIXdaEoLa',
    priceId: 'price_1QPRpfPTLOkrCn0ejSDDnyHe',
  },
];

export const getPaymentDataOffer = () =>
  process.env.NODE_ENV === 'production' ? offerData[0] : offerData[1];
