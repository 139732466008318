import { useEffect, useState } from 'react';

const useCountdown = (targetDate: number, onComplete?: () => void) => {
  const [timeRemaining, setTimeRemaining] = useState(targetDate);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          onComplete && onComplete();
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;

  return [hours, minutes, seconds];
};

export { useCountdown };
